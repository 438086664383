#about-us {
	@import "section-hero";
	@import "section-two-col-boxes";
	@import "section-feedback-carousel";

	.section-team {
		.sub-content {
			@media screen and (min-width: 1700px) {
				margin: 0 auto;
				max-width: 1326px;
				width: 100%;
			}

			.list-team {
				@extend %list-default, %flex-column;

				.item-member {
					@extend %flex-row;

					@include mq(mobile) {
						flex-direction: column-reverse;
					}

					&.mod-reverse {
						flex-direction: row-reverse;

						@include mq(mobile) {
							flex-direction: column-reverse;
						}
					}

					.copy {
						@extend %flex-column;

						justify-content: center;
						padding: 93px 57px;

						@media screen and (max-width: 1500px) {
							padding: 93px 3vw;
						}

						@include mq(mobile) {
							padding: 50px 20px;
						}

						header {
							align-items: center;
							display: flex;

							h3 {
								color: $color-black;
								font-size: $font-size-h1;
								line-height: 35px;
								margin-bottom: 0;

								@media screen and (max-width: 1500px) {
									font-size: 2.7vw;
								}

								@include mq(mobile) {
									font-size: $font-size-h4;
									line-height: 24px;
								}
							}

							a {
								align-items: center;
								display: flex;
								margin-left: 20px;
							}
						}

						h4 {
							font-size: $font-size-larger;

							@include mq(mobile) {
								font-size: $font-size-med-large;
							}
						}

						p {
							color: $color-text;
							font-size: $font-size-large;
							line-height: 27px;
							margin-bottom: 40px;

							@media screen and (max-width: 1500px) {
								font-size: 1.52vw;
								line-height: 2.2vw;
								margin-bottom: 3.2vw;
							}

							@include mq(mobile) {
								font-size: $font-size-med;
								line-height: 28px;
								margin-bottom: 30px;
							}

							&:last-of-type {
								margin-bottom: 0;
							}
						}

						.btns {
							@extend %flex-row;

							margin-top: 40px;

							@media screen and (max-width: 1500px) {
								margin-top: 4vw;
							}

							@include mq(mobile) {
								margin-top: 40px;
							}

							.btn {
								max-width: 188px;
								margin-right: 21px;

								@media screen and (max-width: 1500px) {
									font-size: 1.7vw;
									height: 3.5vw;
									max-width: 17vw;
								}

								@include mq(mobile) {
									font-size: $font-size-med;
									height: 41px;
									max-width: 170px;
								}

								&:nth-of-type(2) {
									margin-right: 0;
								}
							}
						}
					}

					.square {
						background-color: #efefef;
						height: 50vw;
						width: 50vw;

						@media screen and (min-width: 1700px) {
							height: 39vw;
							max-height: 663px;
							width: 50%;
						}

						@include mq(mobile) {
							height: auto;
							min-height: 370px;
							width: 100%;
						}
					}

					.image {
						position: relative;

						& > img {
							@extend %image-cover;
						}
					}
				}
			}
		}
	}
}

// Define placeholders within here to be used within further sass.
%container {
	width: $container-width;
	max-width: $container-max-width;
	margin: 0 auto;

	// Example of how to extend container. Used via `@extend %container-large`
	&-large {
		@extend %container;

		max-width: $container-max-width-large;
	}

	&-small {
		@extend %container;

		max-width: $container-max-width-small;
	}

	@include mq(mobile) {
		width: $container-width-mobile;
		max-width: $container-max-width-mobile;
		margin: 0 auto;
	}
}

%clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

%noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

%nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

%list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

.loading {
	background: rgba($color-white, 0.5);
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: opacity 0.3s ease;
	width: 100%;
	z-index: -1;

	&.mod-show {
		opacity: 1;
		z-index: 9;
	}

	img {
		height: 60px;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 60px;
	}
}

%image {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	&-cover {
		@extend %image;

		object-fit: cover;
	}

	&-contain {
		@extend %image;

		object-fit: contain;
	}
}

// Flex Placeholders

%flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

%flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

%flex-end {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

%flex-start {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

%flex-space-around {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

%flex-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

%flex-column {
	display: flex;
	flex-direction: column;
}

%flex-row {
	display: flex;
	flex-direction: row;
}

%flex-grow {
	display: flex;
	flex: 1 0 auto;
}

%flex-align-center {
	display: flex;
	align-items: center;
}

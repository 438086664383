#blog-post {
	.section-hero {
		padding: 55px 0;

		@include mq(mobile) {
			padding: 35px 0;
		}


		.wrap-hero {
			@extend %container;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			position: relative;

			@include mq(mobile) {
				flex-wrap: wrap;
			}


			.sub-copy {
				max-width: 430px;

				@include mq(mobile) {
					width: 100%;
				}


				h3 {
					color: $color-text;
					font-size: $font-size-standard;
					margin: 0 0 30px;

					@include mq(mobile) {
						display: none;
					}
				}

				h1 {
					margin: 0 0 20px;

					@include mq(mobile) {
						font-size: $font-size-h2;
					}
				}

				p {
					font-size: $font-size-large;
					line-height: $line-height-large;
					margin: 0;
				}

				.sub-social {
					position: absolute;
					bottom: 0;
					left: 0;
					cursor: pointer;

					@include mq(mobile) {
						position: relative;
						padding: 30px 0;
					}


					.sub-date-mob {
						display: none;

						h5 {
							margin: 0 20px 0 0;
							font-size: $font-size-med;
						}

						@include mq(mobile) {
							display: inline-block;
						}
					}

					ul.list-social {
						@extend %list-default;

						width: 25px;
						overflow: hidden;
						transition: all 0.3s ease;
						display: flex;

						@include mq(mobile) {
							padding: 20px 0 0;
						}


						&:hover,
						&:focus {
							width: 100%;
						}

						li {
							margin: 0 22px 0 0;
							padding: 0;
							display: inline-block;

							.svg-icon {
								transition: color 0.2s ease;
								color: $color-borders;

								&:hover {
									color: $color-primary;
								}
							}
						}
					}
				}
			}

			.sub-image {
				height: 320px;
				width: 550px;
				margin: 0 0 0 auto;

				@include mq(mobile) {
					width: 100%;
					height: 208px;
					margin: 0;
				}


				ul.list-image {
					@extend %list-default;

					height: 320px;

					@include mq(mobile) {
						height: 208px;
					}


					li.item-image {
						margin: 0;
						height: 320px;

						@include mq(mobile) {
							height: 208px;
						}


						.sub-background {
							height: 320px;
							background-position: center;
							background-size: cover;

							@include mq(mobile) {
								height: 208px;
							}
						}
					}
				}
			}
		}
	}

	.section-post {
		padding: 0 0 70px;

		@include mq(mobile) {
			padding: 10px 0 70px;
		}


		.sub-content {
			@extend %container, %clearfix;
			.sub-copy {
				max-width: 785px;

				p {
					font-size: $font-size-med;
					line-height: $line-height-large;
				}
			}
		}
	}

	.section-related.post-page {
		padding: 60px 0;
		background-color: rgba($color-neutral-dark, 0.2);

		@include mq(mobile) {
			padding: 60px 0 80px;
		}

		.wrap-related {
			@extend %container;
		}

		h2 {
			margin: 0 0 55px;
			font-size: $font-size-h3;

			@include mq(mobile) {
				margin: 0 0 40px;
			}
		}
	}
}

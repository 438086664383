#sitemap {
	padding-top: 100px;

	@include mq(tablet-down) {
		padding-top: 65px;
	}

	.header-main {
		background-color: $color-black;
	}

	.cont-background {
		display: block;
	}

	.wrap-content {
		@extend %container, %clearfix;
	}

	.section-site-map {
		padding: 60px 0;

		h1 {
			margin: 0 0 40px 0;
			text-align: center;
			text-transform: uppercase;
		}

		h2 {
			margin: 20px 0 10px 0;
			text-transform: uppercase;
			font-size: $font-size-med;
		}

		ol,
		ul {
			margin: 0;
			padding: 0;

			li {
				margin: 0 0 3px 18px;
				padding: 0;
			}
		}
	}

	.first_cont {
		width: 48%;
		float: left;
	}

	.second_cont {
		width: 48%;
		float: right;
	}
}

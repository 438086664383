.header-main {
	align-items: center;
	background: transparent;
	display: flex;
	height: $header-height;
	position: fixed;
	top: 0;
	width: 100%;
	transition: top 0.3s ease;
	z-index: 999;

	@include mq(tablet-down) {
		height: 65px;
	}

	&.hide {
		top: -$header-height;
	}

	&.mod-bg {
		background: $color-white;
		display: flex;
		transition: all 0.3s ease;

		.sub-container {
			#nav ul.list-nav li {
				a {
					color: $color-text;

					@include mq(tablet-down) {
						color: $color-white;
					}
				}
			}

			.mob-hamburger {
				.mob-bar {
					background: $color-black;
					transition: all 0.3s ease;
				}

				&.mod-active .mob-bar {
					@include mq(tablet-down) {
						background: $color-white;
					}
				}
			}
		}

		&.mod-active {
			.logo img {
				&.mod-white {
					opacity: 1;
				}

				&.mod-black {
					opacity: 0;
				}
			}
		}
	}

	.sub-logo {
		@include mq(tablet-down) {
			height: 31px;
			z-index: 99;
			width: 130px;
		}

		.logo {
			position: relative;

			@include mq(tablet-down) {
				align-items: center;
				display: flex;
				height: 31px;
				justify-content: flex-end;
				width: 130px;
				z-index: 99;
			}

			img {
				left: 0;
				position: absolute;
				top: 0;

				@include mq(tablet-down) {
					width: 130px;
				}

				&.mod-hide {
					opacity: 0;
				}
			}
		}
	}

	.sub-container {
		@extend %container;

		display: flex;

		@include mq(tablet-down) {
			align-items: center;
		}

		.mob-hamburger {
			display: none;
			cursor: pointer;
			height: 20px;
			overflow: hidden;
			position: relative;
			transition: width 0.3s ease;
			width: 24px;
			z-index: 999;

			@include mq(tablet-down) {
				display: block;
			}

			@include mq(tablet-down) {
				margin-left: auto;
			}

			.mob-bar {
				background: $color-white;
				border-radius: 20px;
				display: block;
				height: 2px;
				opacity: 1;
				position: absolute;
				transform: rotate(0deg);
				transition: 0.1s ease;
				width: 100%;
				z-index: 9999;
			}

			.mob-bar-1 {
				top: 0;
			}

			.mob-bar-2 {
				top: 8px;
			}

			.mob-bar-3 {
				top: 16px;

				@include mq(tablet-down) {
					width: 13px;
				}
			}

			&.mod-active {
				.mob-bar-1 {
					top: 9px;
					transform: rotate(135deg);
				}

				.mob-bar-2 {
					left: -60px;
					opacity: 0;
				}

				.mob-bar-3 {
					transform: rotate(-135deg);
					top: 9px;
					width: 100%;
				}
			}
		}

		#nav {
			align-items: center;
			display: flex;
			margin-left: auto;

			@include mq(tablet-down) {
				align-items: center;
				background: $color-primary;
				display: flex;
				height: 100vh;
				justify-content: center;
				left: 0;
				padding-top: 0;
				position: absolute;
				text-align: center;
				top: 0;
				transition: opacity 0.3s;
				width: 100%;

				&.mod-hide {
					opacity: 0;
					top: -10000%;
					transition: opacity 0.3s 0.3s, top 2s 2s;

					li {
						pointer-events: none;
					}
				}
			}

			ul.list-nav {
				@extend %list-default;

				@include mq(tablet-down) {
					display: flex;
					flex-direction: column;
					width: 100%;
				}

				li {
					display: inline-block;
					margin-right: 70px;

					@include mq(tablet-down) {
						@include transition;

						display: inline-block;
						animation-timing-function: cubic-bezier(
							0.45,
							0.005,
							0,
							1
						);
						opacity: 1;
						transform: translateY(0);
						transition: opacity 0.5s;
						margin-right: 0;

						&.mod-hide {
							opacity: 0;
							transition: all 0.3s;
							pointer-events: unset;
							transform: translateY(-40px);
						}
					}

					&:last-child {
						margin-right: 0;
					}

					&.mod-active,
					&:hover {
						a {
							color: $color-primary;

							@include mq(tablet-down) {
								color: $color-black;
							}
						}
					}

					& > a {
						color: $color-white;
						font-size: $font-size-larger;
						transition: color 0.3s ease;

						@include mq(tablet-down) {
							color: $color-white;
							display: block;
							font-size: $font-size-h1;
							padding: 5px 20px;
							transition: color 0.3s ease;

							&:hover {
								color: $color-black;
							}
						}
					}
				}
			}
		}
	}
}

.section-cookies {
	@extend %container-large;

	align-items: center;
	background: $color-black;
	border-radius: 6px;
	bottom: 19px;
	display: flex;
	height: 63px;
	left: 50%;
	padding: 0 21px;
	position: fixed;
	transform: translateX(-50%);
	width: 100%;
	z-index: 999;

	@include mq(tablet-down) {
		height: auto;
		padding: 20px;
	}

	&.mod-hide {
		display: none;
	}

	.sub-content {
		align-items: center;
		display: flex;
		width: 100%;

		@include mq(tablet-down) {
			flex-direction: column;
		}

		.sub-text {
			flex: 1;

			& > p {
				color: $color-white;
				font-size: 15px;
				font-weight: $font-weight-regular;
				margin-bottom: 0;

				a {
					font-weight: $font-weight-semibold;
					color: $color-white;
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 70px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 20px;
			}

			.btn {
				background: $color-white;
				color: $color-black;
				display: inline-block;
				font-size: $font-size-standard;
				font-weight: $font-weight-regular;
				height: 36px;
				transition: background 0.3s ease;
				width: 111px;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					padding: 4px 0;
					width: 115px;
				}

				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}
			}
		}
	}
}

.section-hero {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	height: 100vh;
	margin: 0;
	padding: 145px 0 250px;
	position: relative;
	width: 100%;

	@include mq(tablet-down) {
		padding: unset;
	}

	&:before {
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.83) 0%,
			rgba(0, 0, 0, 0.36) 100%
		);
		content: "";
		height: 100%;
		opacity: 0.4;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 9;
	}

	&:after {
		content: "";
		background: linear-gradient(
			281.46deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.56) 100%
		);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.sub-arrow-wrapper {
		align-items: center;
		bottom: 0;
		display: flex;
		left: 0;
		position: absolute;
		height: 60px;
		width: 100%;
		z-index: 99;

		a {
			margin: 0 auto;
		}
	}

	.sub-gradient {
		display: none;

		@include mq(tablet-down) {
			background: linear-gradient(
				to top,
				rgba(0, 0, 0, 0.4),
				rgba(0, 0, 0, 0)
			);
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	.sub-content {
		@extend %container;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		z-index: 99;

		@include mq(tablet-down) {
			justify-content: center;
			text-align: center;
		}

		h1 {
			color: $color-white;
			font-size: $font-size-hero-large;
			font-weight: $font-weight-medium;
			line-height: 72px;
			margin-bottom: 2px;

			@include mq(tablet-down) {
				font-size: 49px;
				line-height: 55px;
			}
		}

		p {
			color: $color-white;
			font-size: $font-size-h1;
			margin-bottom: 25px;

			@include mq(tablet-down) {
				font-size: 23px;
				letter-spacing: 0.6px;
			}
		}

		a {
			background-color: $color-primary;
			border-radius: 25px;
			color: $color-white;
			font-size: 18px;
			font-weight: $font-weight-book;
			min-width: 213px;
			max-width: fit-content;
			padding: 7px 28px 11px 29px;
			text-align: center;
			width: 213px;

			&:hover,
			&:focus {
				background: highlight-by($color-buttons, 5%);
			}

			@include mq(tablet-down) {
				font-size: 16px;
				margin: 0 auto;
				min-width: 185px;
			}
		}
	}
}

ul {
	margin: 0 0 10px 14px;
	padding: 0;

	li {
		margin: 0 0 0 14px;
		padding: 0;
	}
}

ul.slick-dots {
	@extend %list-default;

	display: flex;

	li {
		&:not(:last-child) {
			margin: 0 7px 0 0;
		}

		&.slick-active button {
			background-color: $color-primary;
			opacity: 1;
		}

		button {
			background-color: rgba(255, 255, 255, 0.11);
			border-radius: 50%;
			border: none;
			cursor: pointer;
			display: block;
			height: 12px;
			outline: none;
			padding: 0;
			text-indent: -9999px;
			transition: all 0.3s ease;
			width: 12px;
		}
	}
}

.section-customer-feedback {
	min-height: 700px;
	padding: 115px 0;
	position: relative;

	@include mq(tablet-down) {
		height: auto;
		min-height: auto;
		padding: 55px 0;
	}

	&:before {
		content: "";
		background-color: rgba(0, 0, 0, 0.65);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}

	& > img {
		@extend %image-cover;

		@include mq(tablet-down) {
			object-fit: none;
			object-position: center center;
		}
	}

	.content {
		@extend %container;

		position: relative;
		z-index: 9;

		& > h3 {
			color: $color-white;
			font-size: $font-size-largerer;
			margin-bottom: 50px;

			@include mq(tablet-down) {
				font-size: $font-size-h1;
			}
		}

		.feedback-dots {
			position: absolute;
			right: 0;
			top: 0;

			@include mq(tablet-down) {
				top: 20px;
			}
		}

		.testimonial-wrap {
			max-width: 1120px;
			position: relative;
			width: 100%;

			@include mq(tablet-down) {
				padding-top: 50px;
			}

			svg {
				height: 95px;
				position: absolute;
				width: 105px;

				@include mq(tablet-down) {
					height: 45px;
					width: 45px;
				}

				&.quote-left {
					left: 0;
					top: -10px;
				}

				&.quote-right {
					right: 0;
					bottom: 0;
				}
			}

			ul.list-feedback {
				@extend %list-default;

				max-width: 975px;
				padding-left: 155px;
				width: 100%;

				@include mq(tablet-down) {
					padding-left: 0;
				}

				li.item-feedback {
					margin: 0;

					.quote {
						display: flex;
						margin-bottom: 15px;

						@include mq(tablet-down) {
							flex-direction: column;
						}

						.content {
							flex: 1;

							@include mq(tablet-down) {
								margin: 0;
							}

							&:first-of-type {
								margin-right: 52px;

								@include mq(tablet-down) {
									margin: 0;
								}
							}

							p {
								color: $color-white;
								font-size: $font-size-large;
								line-height: 27px;

								@include mq(tablet-down) {
									font-size: $font-size-med;
								}
							}
						}
					}

					.name {
						color: $color-white;
						font-size: $font-size-large;
						line-height: 27px;
					}
				}
			}
		}
	}
}

/* COLOURS */
$color-black: #000000;
$color-white: #ffffff;

$color-body: $color-white;

$color-neutral: #f5f2f5;
$color-neutral-dark: #607d8b;

$color-borders: #aaaaaa;
$color-borders-light: #c8c8c8;

$color-text: #404040;
$color-text-light: #bababa;

$color-headings: #263238;

$color-error: #ff0000;

$color-primary: #f26122;
$color-secondary: #039be5;

$color-buttons: $color-primary;

$color-link: $color-primary;
$color-link-hover: lighten($color-link, 5%);
$color-link-active: $color-link-hover;

$color-validation-error: #cd0000;
$color-validation-success: #4eb73c;

/* FONTS */
$font-standard: pill-gothic-300mg, Arial, Helvetica, Sans-serif;

$font-base: 14;

$font-size-base: $font-base * 1px;
$font-size-standard: 14 / $font-base * 1rem;
$font-size-tiny: 10 / $font-base * 1rem;
$font-size-small: 13 / $font-base * 1rem;
$font-size-small-med: 15 / $font-base * 1rem;
$font-size-med: 16 / $font-base * 1rem;
$font-size-med-large: 18 / $font-base * 1rem;
$font-size-large: 19 / $font-base * 1rem;
$font-size-larger: 22 / $font-base * 1rem;
$font-size-largerer: 35 / $font-base * 1rem;
$font-size-largest: 40 / $font-base * 1rem;

$font-size-h1: 33 / $font-base * 1rem;
$font-size-h2: 30 / $font-base * 1rem;
$font-size-h3: 24 / $font-base * 1rem;
$font-size-h4: $font-size-large;
$font-size-h5: $font-size-med;
$font-size-h6: $font-size-standard;

$font-size-hero-standard: $font-size-larger;
$font-size-hero-small: 20 / $font-base * 1rem;
$font-size-hero-med: 50 / $font-base * 1rem;
$font-size-hero-large: 75 / $font-base * 1rem;

$font-weight-light: 200;
$font-weight-book: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$headings-font: $font-standard;
$headings-font-weight: $font-weight-light;
$headings-color: $color-headings;
$headings-line-height: 1.4;

$line-height-base: 1.55;
$line-height-large: 2;

$radius-button: 22px;
$padding-button: 15px;
$text-size-button: $font-size-large;

/* STRUCTURE */
$viewport-width: 100%;
$container-width: 95%;
$container-max-width: 1326px;
$container-max-width-small: 900px;
$container-max-width-large: 1326px;
$header-height: 110px;

/* MOBILE STRUCTURE */
$container-width-mobile: 100%;
$container-gutter-mobile: 30px;
$container-max-width-mobile: calc(100% - #{$container-gutter-mobile});

/* MEDIA QUERY */
$mq-desktop-size: $container-max-width;
$mq-tablet-size: 1024px;
$mq-mobile-size: 768px;

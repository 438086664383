#home {
	@import "section-hero";
	@import "section-two-col-boxes";
	@import "section-feedback-carousel";

	.section-black-block {
		background-color: $color-black;
		padding: 110px 0;

		@include mq(tablet-down) {
			padding: 55px 0;
		}

		.sub-content {
			@extend %container;

			.title {
				color: $color-white;
				font-size: $font-size-largest;
				font-weight: $font-weight-medium;
				margin-bottom: 60px;

				@include mq(tablet-down) {
					font-size: $font-size-h1;
					margin-bottom: 40px;
				}
			}

			.list-benefits {
				@extend %list-default;
				display: flex;
				justify-content: space-between;
				margin-bottom: 80px;

				@include mq(tablet-down) {
					flex-direction: column;
					margin-bottom: 20px;
				}

				.item-benefit {
					width: calc((100% / 3) - 50px);

					@include mq(tablet-down) {
						margin-bottom: 45px;
						width: 100%;
					}

					.icon {
						max-height: 54px;
						margin-bottom: 25px;

						img {
							display: block;
							max-height: 54px;
						}
					}

					h3 {
						color: $color-white;
						font-size: $font-size-large;
						font-weight: $font-weight-semibold;
						margin-bottom: 10px;

						@include mq(tablet-down) {
							margin-bottom: 5px;
						}
					}

					p {
						color: $color-white;
						font-size: $font-size-med-large;
						line-height: 27px;

						&:only-child,
						&:last-child {
							margin-bottom: 0;
						}

						@include mq(tablet-down) {
							font-size: $font-size-med;
						}
					}
				}
			}

			a {
				color: $color-white;
				font-size: $font-size-small-med;
				font-weight: $font-weight-regular;
				text-transform: uppercase;

				img {
					margin-right: 12px;
				}
			}
		}
	}

	.section-last-block {
		padding: 85px 0 90px;

		@include mq(tablet-down) {
			padding: 55px 0;
		}

		.sub-content {
			@extend %container;

			h4 {
				font-size: $font-size-largest;
				line-height: 52px;
				margin-bottom: 35px;

				@include mq(tablet-down) {
					font-size: $font-size-h1;
				}
			}

			.copy {
				max-width: 966px;

				p {
					color: $color-black;
					font-size: $font-size-largerer;
					line-height: 52px;

					@include mq(tablet-down) {
						font-size: $font-size-large;
						line-height: 34px;
					}

					&:only-child,
					&:last-child {
						margin-bottom: 0;
					}
				}

				a.btn {
					margin-top: 40px;
				}
			}
		}
	}
}

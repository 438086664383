.section-two-col-block {
	padding: 123px 0 140px;

	@include mq(tablet-down) {
		padding: 55px 0 40px 0;
	}

	.sub-content {
		@extend %container;

		display: flex;
		height: 100%;

		@include mq(tablet-down) {
			flex-direction: column;
		}

		.content-left {
			background-position: 10% 0%;
			background-repeat: no-repeat;
			background-size: 100%;
			height: 100%;
			margin-right: 35px;
			padding-right: 125px;
			width: 50%;

			@include mq(tablet-down) {
				background-image: none;
				margin-bottom: 20px;
				margin-right: 0;
				padding-right: 20px;
				width: 100%;
			}

			h1 {
				color: $color-black;
				font-size: 35px;
				font-weight: 500;
				line-height: 52px;

				@include mq(tablet-down) {
					font-size: $font-size-h3;
					line-height: 36px;
					width: 100%;
				}
			}
		}

		.content-right {
			width: 50%;

			@include mq(tablet-down) {
				width: 100%;
			}

			.text-wrapper {
				display: flex;
				justify-content: space-between;

				@include mq(tablet-down) {
					flex-direction: column;
				}

				.content {
					flex: 1;

					&:first-of-type {
						margin-right: 47px;
					}

					p {
						color: $color-text;
						font-size: $font-size-large;
						font-weight: $font-weight-regular;
						line-height: 27px;

						@include mq(tablet-down) {
							font-size: $font-size-med-large;
							line-height: 30px;
							width: 100%;
						}
					}
				}
			}
		}
	}
}

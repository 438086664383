#error, #not_found {
	.section-404 {
		padding: 80px 0 300px;

		@include mq(tablet-down) {
			padding: 50px 0 150px;
		}


		.wrap-content {
			@extend %container;

			h1 {
				color: $color-primary;
				font-size: 7em;
				font-weight: $font-weight-semibold;
				margin-bottom: 10px;

				@include mq(tablet-down) {
					font-size: $font-size-hero-large;
				}
			}

			h2 {
				font-size: $font-size-h1;
				font-weight: $font-weight-semibold;

				@include mq(tablet-down) {
					font-size: $font-size-h2;
				}
			}

			p {
				font-size: $font-size-larger;

				@include mq(tablet-down) {
					font-size: $font-size-large;
				}
			}
		}
	}
}

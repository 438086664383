#services {
	@import "section-hero";
	@import "section-two-col-boxes";

	.section-services-boxes {
		background-color: #c9c8c7;
		padding: 110px 0 170px;

		@include mq(tablet-down) {
			padding: 55px 0;
		}

		.sub-content {
			@extend %container;

			h2 {
				font-size: $font-size-largest;
				line-height: 52px;
				margin-bottom: 55px;

				@include mq(tablet-down) {
					font-size: $font-size-h1;
					margin-bottom: 40px;
				}
			}

			ul.list-services-boxes {
				@extend %list-default, %flex-row, %flex-wrap;

				& > li {
					background: rgba($color-white, 0.55);
					border-radius: 21px;
					margin-bottom: 20px;
					margin-right: 20px;
					min-height: 250px;
					padding: 18px;
					width: calc(100% / 4 - 20px);

					@include mq(tablet-down) {
						width: calc(100% / 2 - 20px);
					}

					@include mq(mobile) {
						margin-right: 0;
						min-height: auto;
						padding: 25px;
						width: 100%;
					}

					&:nth-child(4n) {
						margin-right: 0;
					}

					h4 {
						font-size: $font-size-large;
						font-weight: $font-weight-semibold;
						line-height: 27px;
						margin-bottom: 15px;
					}

					p {
						font-size: $font-size-small-med;
						line-height: 27px;

						&:only-child,
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

#blog {
	.wrap-list {
		@extend %container;
	}

	.section-list {
		padding: 70px 0;

		@include mq(mobile) {
			padding: 60px 0;
		}


		h1 {
			margin: 0 0 60px;
			font-size: $font-size-h1;
			color: $color-text;

			@include mq(mobile) {
				margin: 0 0 40px;
				font-size: $font-size-h2;
			}
		}

		background: rgba($color-neutral-dark, 0.2);
	}
}

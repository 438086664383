#page {
	padding-top: 100px;

	@include mq(tablet-down) {
		padding-top: 65px;
	}

	.header-main {
		background-color: $color-black;
	}

	.section-content {
		@extend %container;

		padding: 60px 0 200px;
	}
}

#contact {
	padding-top: 100px;

	@include mq(tablet-down) {
		padding-top: 65px;
	}

	.header-main {
		background-color: $color-black;
	}

	.section-form {
		padding: 70px 0 200px;

		@include mq(tablet-down) {
			padding: 30px 0;
		}

		h1 {
			font-size: $font-size-hero-med;
			font-weight: $font-weight-regular;
			line-height: 106px;

			@include mq(tablet-down) {
				font-size: $font-size-h1;
				line-height: 52px;
			}
		}

		.sub-content {
			@extend %container;

			.sub-wrapper {
				@extend %flex-row, %flex-space-between;

				align-items: flex-start;

				@include mq(tablet-down) {
					flex-direction: column;
				}

				h2 {
					font-size: $font-size-h2;
					font-weight: $font-weight-regular;
					margin-bottom: 30px;

					@include mq(tablet-down) {
						font-size: $font-size-larger;
						margin-bottom: 20px;
					}
				}

				.contact-info {
					max-width: 465px;
					padding-right: 50px;
					width: 100%;

					@include mq(tablet-down) {
						max-width: none;
						padding-right: 0;
					}

					p {
						font-size: $font-size-med-large;
						line-height: 27px;

						@include mq(tablet-down) {
							padding-right: 200px;
						}

						@include mq(mobile) {
							padding-right: 20px;
						}
					}

					ul.list-contact-details {
						@extend %list-default;

						margin-bottom: 30px;

						& > li {
							font-size: $font-size-larger;
							line-height: 27px;

							@include mq(tablet-down) {
								font-size: $font-size-large;
							}

							span {
								display: inline-block;
								margin-right: 30px;
								min-width: 55px;

								@include mq(tablet-down) {
									margin-right: 0;
									min-width: 48px;
								}
							}

							a {
								color: $color-text;

								&[href^="mailto:"] {
									color: $color-primary;
									font-weight: $font-weight-bold;
								}
							}
						}
					}

					.directions {
						h4 {
							color: $color-black;
							font-weight: $font-weight-medium;
							margin-bottom: 0;
						}

						p {
							font-size: $font-size-med-large;
							line-height: 27px;
						}
					}
				}

				.contact-form {
					max-width: 653px;
					width: 100%;

					@include mq(tablet-down) {
						margin-top: 30px;
						max-width: none;
					}

					& > form {
						position: relative;

						@include mq(mobile) {
							max-width: none;
						}

						&.mod-hide {
							display: none;
						}

						button.btn {
							margin-left: 153px;
							padding-left: 5px;
							padding-right: 0;

							@include mq(tablet-down) {
								margin-left: 115px;
							}
						}
					}

					.form-thanks {
						display: none;

						&.mod-show {
							display: block;
						}

						h3 {
							font-size: $font-size-h1;
							font-weight: $font-weight-regular;
							margin-bottom: 10px;
						}

						p {
							font-size: $font-size-med-large;
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

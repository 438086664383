.footer-main {
	background: $color-black;
	min-height: 342px;
	padding: 80px 0;
	z-index: 1;

	@include mq(mobile) {
		min-height: auto;
		padding: 60px 0;
	}

	.sub-content {
		@extend %container, %flex-row;

		@include mq(mobile) {
			flex-direction: column;
		}

		.sub-logo-block {
			@extend %flex-column;

			.logo {
				margin-bottom: 45px;
			}

			.linkedin {
				align-self: flex-start;

				@include mq(tablet-down) {
					display: none;
				}
			}
		}

		.sub-links {
			@extend %flex-row;

			margin-left: 225px;

			@include mq(mobile) {
				flex-direction: column;
				margin-left: 0;
			}

			ul {
				@extend %list-default;

				&:not(:last-child) {
					margin-right: 160px;

					@include mq(mobile) {
						margin-bottom: 40px;
						margin-right: 0;
					}
				}

				& > li {
					&:first-child {
						color: $color-white;
						font-size: 17 / $font-base * 1rem;
						margin-bottom: 40px;

						@include mq(mobile) {
							font-size: $font-size-large;
							margin-bottom: 10px;
						}
					}

					a {
						color: #dedede;
						font-size: $font-size-small-med;
						line-height: 17px;

						@include mq(mobile) {
							font-size: $font-size-med;
							line-height: 24px;
						}
					}
				}
			}
		}

		.sub-misc {
			@extend %flex-column;

			margin-left: auto;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 40px;

				img {
					align-self: flex-start;
				}
			}

			span.copyright {
				color: #dedede;
				font-size: $font-size-small;
				display: block;
				margin-top: auto;

				@include mq(tablet-down) {
					margin-top: 20px;
				}
			}
		}
	}
}
